import { ChakraTheme, extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const brand = {
  50: "#fcf0e3",
  100: "#e8dbc7",
  200: "#d6c5a6",
  300: "#c5b086",
  400: "#b49c65",
  500: "#9a7e4b",
  600: "#785d39",
  700: "#574028",
  800: "#342415",
  900: "#140800",
};

const theme: ChakraTheme = extendTheme({
  config,
  colors: {
    brand,
  },
});

export default theme;
