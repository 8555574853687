import React from "react";
import { chakra, IconButton, SpaceProps } from "@chakra-ui/react";

export const StyledTableWrapper = chakra("div", {
  baseStyle: {
    display: "block",
    maxWidth: "100%",
    overflowX: "auto",
  },
});

export const StyledTable = chakra("table", {
  baseStyle: {
    boxSize: "border-box",
    width: "100%",
    borderRadius: "4px",
    whiteSpace: "nowrap",
  },
});

export const TableHead = chakra("thead", {
  baseStyle: {},
});

export const TableBody = chakra("tbody", {
  baseStyle: {},
});

export const Th = chakra("th", {
  baseStyle: {
    margin: 0,
    padding: 4,
    borderBottomWidth: 1,
    //   /* The secret sauce */
    //   /* Each cell should grow equally */
    width: "1%",
    //   /* But "collapsed" cells should be as small as possible */
    "&.collapse": {
      width: "0.0000000001%",
    },
  },
});

export const Td = chakra("td", {
  baseStyle: {
    margin: 0,
    padding: 4,
    borderBottomWidth: 1,
    //   /* The secret sauce */
    //   /* Each cell should grow equally */
    width: "1%",
    //   /* But "collapsed" cells should be as small as possible */
    "&.collapse": {
      width: "0.0000000001%",
    },
  },
});

export const TableRow = chakra("tr", {
  baseStyle: {
    _hover: {
      backgroundColor: "rgba(0, 0, 0, 0.02)",
    },
  },
});

type TableIconButtonProps = SpaceProps & {
  icon: any;
  onClick:
    | ((event: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
  isDisabled: boolean;
  variantColor?: string;
};

export const TableIconButton: React.FC<TableIconButtonProps> = ({
  icon,
  onClick,
  isDisabled,
  children,
  ...rest
}) => {
  return (
    <IconButton
      size="sm"
      {...rest}
      icon={icon}
      borderWidth={1}
      onClick={onClick}
      fontSize="20px"
      isDisabled={isDisabled}
      aria-label="Table Icon button"
    >
      {children}
    </IconButton>
  );
};
