import { format, parseISO } from "date-fns";

const columns = [
  {
    Header: "Full Name",
    accessor: "name",
    width: 100,
    maxWidth: 120,
  },
  {
    Header: "Joined On",
    accessor: "startDate",
    Cell: ({ value }: { value: string }) => {
      return format(parseISO(value), "dd/MMM/yy");
    },
  },

  {
    Header: "Contact",
    accessor: "phone",
  },
];

export default columns;
