import React from "react";
import {
  Button,
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useClipboard,
  VStack,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  Box,
} from "@chakra-ui/react";
import { LinkIcon } from "@chakra-ui/icons";
import { ImFilesEmpty, ImWhatsapp, ImTelegram } from "react-icons/im";

const ShareLinkModal = ({
  link,
  isOpen,
  onClose,
}: {
  link: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { hasCopied, onCopy } = useClipboard(link);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="sm"
      // closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent m={4} px={2} py={4}>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <Text fontWeight={600}>Share Link</Text>
            <Text color={"gray.600"}>
              Please note that this is a private link and will expire after 48
              hours.
            </Text>
          </Box>
          <InputGroup size="md">
            <InputLeftElement
              pointerEvents="none"
              children={<LinkIcon color="gray.300" />}
            />
            <Input value={link} type="text" disabled />
          </InputGroup>

          <VStack spacing={2} mt={4}>
            <Button
              colorScheme={hasCopied ? "green" : "gray"}
              isFullWidth
              aria-label="Copy to Clipboard"
              onClick={onCopy}
              leftIcon={<ImFilesEmpty />}
            >
              {hasCopied ? "Copied" : "Copy to Clipboard"}
            </Button>
            <Button
              isFullWidth
              colorScheme="whatsapp"
              aria-label="Share With WhatsApp"
              leftIcon={<ImWhatsapp />}
              onClick={() =>
                window.open(`https://wa.me/?text=${link}`, "_blank")
              }
            >
              Share with WhatsApp
            </Button>
            <Button
              isFullWidth
              colorScheme="telegram"
              aria-label="Share With Telegram"
              leftIcon={<ImTelegram />}
              onClick={() =>
                window.open(`https://t.me/share/url?url=${link}`, "_blank")
              }
            >
              Share with Telegram
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareLinkModal;
