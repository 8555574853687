import * as yup from "yup";

const UpdateBankAccountValidation = yup
  .object()
  .shape({
    ic_type: yup.string().defined(),
    ic_number: yup.string().label("IC Number").min(4).max(20).defined(),
    bank_id: yup.number().defined(),
    bank_account_name: yup
      .string()
      .min(4)
      .max(24)
      .label("Account Holder Name")
      .defined(),
    bank_account_no: yup
      .string()
      .label("Account Number")
      .min(4)
      .max(20)
      .defined(),
  })
  .defined();

export default UpdateBankAccountValidation;
