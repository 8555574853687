import React, { useEffect, useState } from "react";
import { Spinner, Stack, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import Card from "../../../components/Card";
import api from "../../../util/api";

type CommissionStatsResponse = {
  totalCompletedNotInvoiced: number;
  totalInvoiced: number;
  totalProcessing: number;
} | null;

const CommissionStatsCard = () => {
  const [stats, setStats] = useState<CommissionStatsResponse>(null);

  useEffect(() => {
    api()
      .get("commission/stats")
      .then((res) => {
        // console.log("stats", res.data);
        setStats(res.data.data);
      })
      .catch((err) => console.log("commission/stats", err));
  }, []);

  return (
    <Card py={6} px={8}>
      {stats ? (
        <Stack direction={["column", "row"]} spacing={2}>
          <Stat textAlign="center">
            <StatLabel>Processing</StatLabel>
            <StatNumber>
              <NumberFormat
                value={stats?.totalProcessing}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"RM"}
              />
            </StatNumber>
          </Stat>

          <Stat textAlign="center">
            <StatLabel>Pending Confirmation</StatLabel>
            <StatNumber>
              <NumberFormat
                value={stats?.totalCompletedNotInvoiced}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"RM"}
              />
            </StatNumber>
          </Stat>

          <Stat textAlign="center">
            <StatLabel>Invoiced</StatLabel>
            <StatNumber>
              <NumberFormat
                value={stats?.totalInvoiced}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"RM"}
              />
            </StatNumber>
          </Stat>
        </Stack>
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.300"
          size="lg"
        />
      )}
    </Card>
  );
};

export default CommissionStatsCard;
