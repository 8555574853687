import React from "react";
import { ChakraProps, Flex } from "@chakra-ui/react";

const BottomSection: React.FC<ChakraProps> = ({ children, ...rest }) => {
  return (
    <Flex
      borderTopWidth="1px"
      overflowX="hidden"
      overflowY="hidden"
      p={4}
      flexDirection="column"
      roundedBottomLeft={4}
      roundedBottomRight={4}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default BottomSection;
