import React from 'react';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { AuthStateProvider } from './context/authContext';

export const App = () => {
  return (
    <AuthStateProvider>
      <ChakraProvider theme={theme}>
        <Router>
          <Routes />
        </Router>
      </ChakraProvider>
    </AuthStateProvider>
  );
};
