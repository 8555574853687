import React, { createContext, useReducer } from "react";
import api from "../util/api";
import { AuthUserObjectType } from "./types";

type Action =
  | { type: "updateAuthUser"; payload: AuthUserObjectType }
  | { type: "setAuthIsFetching"; payload: boolean };
type Dispatch = (action: Action) => void;
type State = { isFetching: boolean; authUser: AuthUserObjectType };

const initState = {
  isFetching: true,
  authUser: null,
};

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

function authStateReducer(state: State, action: Action) {
  switch (action.type) {
    case "updateAuthUser": {
      return { authUser: action.payload, isFetching: false };
    }

    case "setAuthIsFetching": {
      return { ...state, isFetching: action.payload };
    }

    default: {
      return state;
    }
  }
}

const AuthStateProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(authStateReducer, initState);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

function useAuthState() {
  const context = React.useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }

  return context;
}

function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }

  return context;
}

// Auth helper functions
const login = async (dispatch: Dispatch) => {
  // console.log("Login flag");
  dispatch({ type: "setAuthIsFetching", payload: true });

  await api()
    .get("/user/me")
    .then((res) => {
      // console.log("/user/me", res.data);
      localStorage.setItem(
        `${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}-isAuth`,
        "true"
      );
      dispatch({ type: "updateAuthUser", payload: res.data.data });
    })
    .catch((err) => console.log("/me failed", err));
};

const logout = async (dispatch: Dispatch) => {
  // console.log("Logout flag");
  localStorage.removeItem(
    `${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}-isAuth`
  );
  dispatch({ type: "updateAuthUser", payload: null });
};

export { AuthStateProvider, useAuthState, useAuthDispatch, login, logout };
