import React from "react";
import {
  // Link,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaLock, FaRegEnvelope } from "react-icons/fa";
import Card from "./Card";
import LoginFormSchema from "../validations/LoginFormValidation";
import api from "../util/api";
import { useAuthDispatch, login } from "../context/authContext";
import { LoginType } from "./types";

const LoginForm: React.FC<any> = () => {
  const authDispatch = useAuthDispatch();
  const toast = useToast();
  const { handleSubmit, register, reset, formState } = useForm({
    resolver: yupResolver(LoginFormSchema),
  });
  const { isSubmitting, errors } = formState;

  const loginSubmit = async (data: LoginType) => {
    await api().get(
      `${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`
    );
    await api()
      .post("/login", data)
      .then(() => {
        login(authDispatch);
      })
      .catch((err) => {
        reset();
        // console.log(err);
        toast({
          title: "Authentication Error",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <Card w={"full"} maxW={"md"} p={8}>
      <Heading size="lg" as="h4" textAlign="center" marginBottom={4}>
        Login
      </Heading>
      <form autoComplete="off" onSubmit={handleSubmit(loginSubmit)}>
        <Stack spacing={4} marginBottom={4}>
          <FormControl id="email" isRequired isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">Email Address</FormLabel>
            <InputGroup>
              <InputLeftElement
                children={<Icon as={FaRegEnvelope} color="gray.300" />}
              />
              <Input
                focusBorderColor="main.500"
                type="email"
                name="email"
                placeholder="name@example.com"
                ref={register}
              />
            </InputGroup>
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="password" isRequired isInvalid={!!errors.password}>
            <Stack justifyContent="space-between" isInline>
              <FormLabel htmlFor="password">Password</FormLabel>
            </Stack>
            <InputGroup>
              <InputLeftElement
                children={<Icon as={FaLock} color="gray.300" />}
              />
              <Input
                focusBorderColor="main.500"
                name="password"
                type="password"
                placeholder="Enter your password"
                ref={register}
              />
            </InputGroup>
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        {/* <Stack justifyContent="flex-end" isInline marginBottom={4}>
          <Link href="#" fontSize="sm" fontWeight="500">
            Forgot Password?
          </Link>
        </Stack> */}
        <Stack>
          <Button
            isLoading={isSubmitting}
            variant="solid"
            type="submit"
            loadingText="Please wait..."
            colorScheme="brand"
          >
            Sign in
          </Button>
        </Stack>
      </form>
    </Card>
  );
};

export default LoginForm;
