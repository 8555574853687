import React from "react";
import { useColorModeValue, Box, BoxProps } from "@chakra-ui/react";
import Header from "../components/Header";
import SideNav from "../components/SideNav";

const Dashboard = ({ children, ...rest }: { children: any }) => {
  const bgColor = useColorModeValue("gray.100", "gray.900");
  return (
    <>
      <Header />
      <Box>
        <SideNav
          display={["none", null, "block"]}
          maxWidth="18rem"
          width="full"
        />
        <Box pl={[0, null, "18rem"]} mt="4rem">
          <Box as="section" bgColor={bgColor} minHeight="calc(100vh - 4rem)">
            <Box {...rest}>{children}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const DashboardLayout: React.FC<BoxProps> = ({ children, ...rest }) => (
  <Dashboard {...rest}>{children}</Dashboard>
);

export default DashboardLayout;
