import * as yup from 'yup';

const UpdatePasswordValidation = yup
  .object()
  .shape({
    current_password: yup
      .string()
      .min(8, 'Must be at least 8 characters')
      .defined(),
    password: yup
      .string()
      .min(8, 'Must be at least 8 characters')
      .matches(
        /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/,
        'Must contain at least one capital letter and number.'
      )
      .defined(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .defined(),
  })
  .defined();

export default UpdatePasswordValidation;
