import React from "react";
import { format, parseISO } from "date-fns";
import NumberFormat from "react-number-format";
import GenerateStatusBadge from "./GenerateStatusBadge";

const columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Issued At",
    accessor: "createdAt",
    Cell: ({ value }: { value: string }) => {
      return format(parseISO(value), "dd/MMM/yy");
    },
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }: { value: number }) => {
      return (
        <NumberFormat
          value={value}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"RM "}
        />
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    // disableFilters: false,
    // Filter: StatusFilterList,
    // filter: "includes",

    Cell: ({ value }: { value: string }) => {
      return <GenerateStatusBadge value={value} />;
    },
  },
  {
    Header: "reference",
    accessor: "reference",
  },
  {
    Header: "Comment",
    accessor: "comment",
  },
  {
    Header: "Last Update",
    accessor: "lastUpdate",
    Cell: ({ value }: { value: string }) => {
      return format(parseISO(value), "dd/MMM/yy");
    },
  },
];

export default columns;
