import React, { useState, useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import api from "../../util/api";
import ShareLinkModal from "../../components/ShareLinkModal";

const InvitationForm: React.FC<{ quota: number }> = ({ quota }) => {
  const toast = useToast();
  const [newLink, setNewLink] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [submitting, setSubmitting] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const onCloseForm = () => setIsOpenForm(false);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const createInvitation = async () => {
    setSubmitting(true);
    await api()
      .post("invitation")
      .then((res) => {
        console.log("inv", res.data);
        setSubmitting(false);
        onCloseForm();
        setNewLink(res.data.data.link);
        // open share link
        onOpen();
        toast({
          title: "Success",
          description: "Invitation created successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.log("inv Err", err);
        setSubmitting(false);
        toast({
          title: "Error",
          description: "Something went wrong. try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <ShareLinkModal isOpen={isOpen} onClose={onClose} link={newLink} />
      <Flex>
        <Button
          colorScheme="brand"
          leftIcon={<AddIcon />}
          onClick={() => setIsOpenForm(true)}
        >
          New Invitation
        </Button>

        <AlertDialog
          isOpen={isOpenForm}
          leastDestructiveRef={cancelRef}
          onClose={onCloseForm}
          // closeOnOverlayClick={false}
          isCentered
          size="sm"
        >
          <AlertDialogOverlay>
            <AlertDialogContent m={4}>
              <AlertDialogCloseButton />
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                New Invitation
              </AlertDialogHeader>

              <AlertDialogBody>
                <Stat textAlign="center" mb={2}>
                  <StatLabel>Available Quota</StatLabel>
                  <StatNumber>{quota}</StatNumber>
                </Stat>
                {quota > 0
                  ? "Are you sure you want to create a new invitation link?"
                  : "Sorry, you don't have any quota."}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseForm}>
                  Cancel
                </Button>
                <Button
                  isLoading={submitting}
                  colorScheme="green"
                  disabled={quota <= 0}
                  onClick={createInvitation}
                  ml={3}
                >
                  Create
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>
    </>
  );
};

export default InvitationForm;
