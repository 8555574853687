import React, { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  InputGroup,
  FormErrorMessage,
  useToast,
  Alert,
  AlertIcon,
  Select,
  Box,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UpdateBankAccountValidation from "../validations/UpdateBankAccountValidation";
import Card from "./Card";
import api from "../util/api";
import mapServerSideErrors from "../util/mapServerSideErrors";
import { UpdateBankAccountType } from "./types";
import { useAuthState, useAuthDispatch } from "../context/authContext";
import { malaysiaBanksList } from "../assets/malaysiaBanksList";
import { icTypesList } from "../assets/icTypesList";

const UpdateBankAccountForm = () => {
  const authDispatch = useAuthDispatch();
  const { authUser } = useAuthState();
  const toast = useToast();
  const { handleSubmit, formState, reset, setError, control } = useForm({
    resolver: yupResolver(UpdateBankAccountValidation),
  });
  const { isSubmitting, errors, isDirty } = formState;

  useEffect(() => {
    reset(authUser!);
  }, [reset, authUser]);

  const updateBankAccountSubmit = async (data: UpdateBankAccountType) => {
    await api()
      .put("/user/bank-account", data)
      .then(() => {
        // console.log(res.data);

        //  update auth user data
        let UpdatedBank = malaysiaBanksList.find(
          (item) => item.id === data.bank_id
        );

        let updatedUserData = {
          ...authUser,
          bank: UpdatedBank,
          bankAccountNo: data.bank_account_no,
          bankAccountName: data.bank_account_name,
          icType: data.ic_type,
          icNumber: data.ic_number,
        };

        authDispatch({ type: "updateAuthUser", payload: updatedUserData });

        toast({
          title: "Success",
          description: "Profile information updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        if (typeof err.errors === "object") {
          mapServerSideErrors(err.errors, setError);
        }
        // else {
        //   console.log(err);
        // }
      });
  };

  return (
    <Card maxW={"xl"} py={6} px={8}>
      <Stack mb={4}>
        <Heading size={"md"}>Update Bank Account</Heading>
      </Stack>

      {!authUser?.bankAccountNo ? (
        <Alert status="warning" my={6}>
          <AlertIcon />
          Plese provide this information in order for us to proceed with your
          payments.
        </Alert>
      ) : null}
      <Box
        as="form"
        autoComplete="off"
        onSubmit={handleSubmit(updateBankAccountSubmit)}
      >
        <Stack spacing={4} direction={["column", "row"]} mb={4}>
          <FormControl
            id="bank_account_name"
            isInvalid={!!errors.bank_account_name}
          >
            <FormLabel>Account Holder Name</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Input}
                type="string"
                name="bank_account_name"
                defaultValue={
                  authUser?.bankAccountName ? authUser?.bankAccountName : ""
                }
              />
            </InputGroup>
            {errors.bank_account_name && (
              <FormErrorMessage>
                {errors.bank_account_name.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Stack spacing={4} direction={["column", "row"]}>
          <FormControl id="ic_type" isInvalid={!!errors.ic_type}>
            <FormLabel>IC Type</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Select}
                name="ic_type"
                placeholder="Select your IC type"
                disabled={icTypesList.length === 0}
                defaultValue={authUser?.icType ? authUser?.icType : " "}
              >
                {icTypesList.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Controller>
            </InputGroup>
            {errors.ic_type && (
              <FormErrorMessage>{errors.ic_type.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="ic_number" isInvalid={!!errors.ic_number}>
            <FormLabel>IC Number</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Input}
                type="string"
                name="ic_number"
                defaultValue={authUser?.icNumber ? authUser?.icNumber : ""}
              />
            </InputGroup>
            {errors.ic_number && (
              <FormErrorMessage>{errors.ic_number.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Stack mt={4} spacing={4} direction={["column", "row"]}>
          <FormControl id="bank_id" isInvalid={!!errors.bank_id}>
            <FormLabel>Bank Name</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Select}
                name="bank_id"
                placeholder="Select your bank"
                disabled={malaysiaBanksList.length === 0}
                defaultValue={authUser?.bank! ? authUser?.bank!.id : 0}
              >
                {malaysiaBanksList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </Controller>
            </InputGroup>
            {errors.bank_id && (
              <FormErrorMessage>{errors.bank_id.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            id="bank_account_no"
            isInvalid={!!errors.bank_account_no}
          >
            <FormLabel>Account Number</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Input}
                type="string"
                name="bank_account_no"
                defaultValue={
                  authUser?.bankAccountNo ? authUser?.bankAccountNo : " "
                }
              />
            </InputGroup>
            {errors.bank_account_no && (
              <FormErrorMessage>
                {errors.bank_account_no.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Box d="flex" justifyContent="flex-end" mt={4}>
          <Button
            isLoading={isSubmitting}
            isDisabled={!isDirty}
            variant="solid"
            type="submit"
            loadingText="Please wait..."
            colorScheme="brand"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default UpdateBankAccountForm;
