import React, { useState, useEffect } from "react";
import { Stack } from "@chakra-ui/react";
import DashboardLayout from "../../layouts/DashboardLayout";
import InvitationsTable from "./InvitationsTable";
import InvitationStatsCard from "./InvitationStatsCard";
import InvitationForm from "./InvitationFrom";
import api from "../../util/api";

type InvitationStatsResponse = {
  completedOrders: number;
  currentAgents: number;
  invitationsCount: number;
  quota: number;
} | null;

const Invitations = () => {
  const [stats, setStats] = useState<InvitationStatsResponse>(null);

  useEffect(() => {
    api()
      .get("invitation/stats")
      .then((res) => {
        setStats(res.data.data);
      })
      .catch((err) => console.log("invitation/stats", err));
  }, []);

  return (
    <DashboardLayout width="full" maxWidth="1280px" mx="auto" px={6} py={6}>
      <Stack spacing={6}>
        <InvitationStatsCard stats={stats} />
        <InvitationForm quota={stats?.quota || 0} />
        <InvitationsTable />
      </Stack>
    </DashboardLayout>
  );
};

export default Invitations;
