import * as yup from "yup";

const UpdateProfileValidation = yup
  .object()
  .shape({
    email: yup.string().email().defined(),
    name: yup.string().min(4).max(24).defined(),
    state_id: yup.number().defined(),
    phone: yup
      .string()
      .min(11)
      .max(12)
      // has some bugs on update form
      // .matches(/^601[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/, {
      //   message: "Phone number should start with 60",
      //   // excludeEmptyString: true,
      // })
      .defined(),
  })
  .defined();

export default UpdateProfileValidation;
