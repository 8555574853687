import React from "react";
import { format, parseISO } from "date-fns";
import NumberFormat from "react-number-format";
import GenerateStatusBadge from "./GenerateStatusBadge";
import StatusFilterList from "./StatusFilterList";

const columns = [
  {
    Header: "ID",
    accessor: "id",
    collapse: true,
  },
  {
    Header: "Order ID",
    accessor: "shopOrderId",
    width: 100,
    maxWidth: 120,
  },
  {
    Header: "Order Date",
    accessor: "createdAt",
    Cell: ({ value }: { value: string }) => {
      return format(parseISO(value), "dd/MMM/yy");
    },
    disableSortBy: false,
    sortDescFirst: true,
  },
  {
    Header: "Order Total",
    accessor: "orderTotal",
    Cell: ({ value }: { value: number }) => {
      return (
        <NumberFormat
          value={value}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"RM "}
        />
      );
    },
  },
  {
    Header: "Level",
    accessor: "level",
    collapse: true,
  },
  {
    Header: "Amount",
    accessor: "amount",
    Cell: ({ value }: { value: number }) => {
      return (
        <NumberFormat
          value={value}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"RM "}
        />
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    disableFilters: false,
    Filter: StatusFilterList,
    filter: "includes",

    Cell: ({ value }: { value: string }) => {
      return <GenerateStatusBadge value={value} />;
    },
  },
  {
    Header: "Comp. Date",
    accessor: "dateCompleted",
    Cell: ({ value }: { value: string }) => {
      return value ? format(parseISO(value), "dd/MMM/yy") : "";
    },
  },
  {
    Header: "Invoice ID",
    accessor: "invoiceId",
  },
];

export default columns;
