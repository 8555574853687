import React from "react";
import { Box, Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import {
  Column,
  Row,
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  Filters,
  SortingRule,
} from "react-table";
import {
  BiChevronRight,
  BiChevronsRight,
  BiChevronLeft,
  BiChevronsLeft,
  BiSort,
  BiSortDown,
  BiSortUp,
} from "react-icons/bi";
import Card from "../Card";
import {
  StyledTable,
  TableHead,
  TableIconButton,
  TableRow,
  StyledTableWrapper,
  Th,
  Td,
  TableBody,
} from "./StyledTableComponents";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";

// Use declaration merging to extend types https://github.com/tannerlinsley/react-table/commit/7ab63858391ebb2ff621fa71411157df19d916ba
declare module "react-table" {
  export interface TableOptions<D extends object>
    extends UsePaginationOptions<D>,
      UseFiltersOptions<D>,
      UseSortByOptions<D> {}

  export interface TableInstance<D extends object = {}>
    extends UsePaginationInstanceProps<D>,
      UseFiltersInstanceProps<D> {}

  export interface TableState<D extends object = {}>
    extends UsePaginationState<D>,
      UseFiltersState<D>,
      UseSortByState<D> {}

  export interface ColumnInstance<D extends object = {}>
    extends UseSortByColumnProps<D>,
      UseFiltersColumnProps<D> {
    collapse: boolean;
  }
}

type CustomTableProps<D extends object = {}> = {
  data: any;
  isLoading?: boolean;
  pageCount?: number;
  tableHeading?: React.ReactNode;
  columns: Column<D>[];
  onRowClick?: (row: Row<D>) => void;
  fetchData: ({
    pageIndex,
    filters,
  }: {
    pageIndex: number;
    filters: Filters<D>;
    sortBy: SortingRule<D>[];
  }) => void;
};

const CustomTable = <D extends {}>({
  columns,
  data,
  tableHeading,
  onRowClick,
  isLoading,
  pageCount: totalPageCount,
  fetchData,
}: CustomTableProps<D>) => {
  const tableColumns = React.useMemo(() => columns, [columns]);
  const tableData = React.useMemo(() => data, [data]);

  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
      disableSortBy: true,
      disableFilters: true,
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,

    // Get the state from the instance
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable<D>(
    {
      columns: tableColumns,
      data: tableData,
      pageCount: totalPageCount,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: "createdAt",
            desc: true,
          },
        ],
      },
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      disableMultiSort: true,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageIndex, filters, sortBy });
  }, [fetchData, pageIndex, filters, sortBy]);

  return (
    <Card flexDirection="column" flex={1} width="100%">
      {/* <pre>
        <code>
          {JSON.stringify(
            {
              pageIndex,
              pageCount,
              canNextPage,
              canPreviousPage,
              pageSize,
              filters,
              sortBy,
            },
            null,
            2
          )}
        </code>
      </pre> */}
      {!!tableHeading && <TopSection>{tableHeading}</TopSection>}
      <StyledTableWrapper position={isLoading ? "relative" : "initial"}>
        {isLoading && (
          <Box
            position="absolute"
            background="rgba(255, 255, 255, 0.7)"
            display="flex"
            w="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            zIndex={2000}
          >
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="brand.300"
              size="xl"
            />
          </Box>
        )}
        <StyledTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getSortByToggleProps()}
                    {...column.getHeaderProps({
                      className: column.collapse ? "collapse" : "",
                    })}
                  >
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text fontWeight="bold">{column.render("Header")}</Text>
                      {column.canFilter ? column.render("Filter") : null}
                      {column.canSort &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon as={BiSortDown} />
                          ) : (
                            <Icon as={BiSortUp} />
                          )
                        ) : (
                          <Icon as={BiSort} />
                        ))}
                    </Flex>
                  </Th>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {page.map(
              (row) =>
                // @ts-ignore
                prepareRow(row) || (
                  <TableRow
                    onClick={() => onRowClick && onRowClick(row)}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <Td
                          {...cell.getCellProps({
                            className: cell.column.collapse ? "collapse" : "",
                          })}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </TableRow>
                )
            )}
          </TableBody>
        </StyledTable>
        {tableData.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" h={50}>
            <Text fontSize="lg">No Data!</Text>
          </Flex>
        ) : null}
      </StyledTableWrapper>

      <BottomSection justifyContent="space-between" flexDirection="row">
        <Flex flexDirection="row">
          <TableIconButton
            mr={2}
            onClick={() => gotoPage(0)}
            isDisabled={!canPreviousPage}
            icon={<BiChevronsLeft />}
          />
          <TableIconButton
            mr={2}
            isDisabled={!canPreviousPage}
            onClick={() => previousPage()}
            icon={<BiChevronLeft />}
          />
        </Flex>
        <Flex justifyContent="center" alignItems="center">
          <Text mr={4}>
            Page{" "}
            <strong>
              {/* {pageIndex + 1} of {pageOptions.length} */}
              {pageIndex + 1}
            </strong>{" "}
          </Text>
        </Flex>
        <Flex flexDirection="row">
          <TableIconButton
            ml={2}
            isDisabled={!canNextPage || tableData.length < pageSize}
            onClick={() => nextPage()}
            icon={<BiChevronRight />}
          />
          <TableIconButton
            ml={2}
            onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
            // isDisabled={!canNextPage}
            isDisabled
            icon={<BiChevronsRight />}
          />
        </Flex>
      </BottomSection>
    </Card>
  );
};

export default CustomTable;
