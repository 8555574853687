import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  InputGroup,
  InputLeftElement,
  FormErrorMessage,
  useToast,
  Box,
  Select,
  FormHelperText,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Card from "./Card";
import { countryStatesList } from "../assets/countryStatesList";
import { PhoneIcon } from "@chakra-ui/icons";
import RegisterAgentValidation from "../validations/RegisterAgentValidation";
import api from "../util/api";
import mapServerSideErrors from "../util/mapServerSideErrors";
import { RegisterAgentType } from "./types";

const RegisterAgentForm: React.FC<{ secret: string }> = ({ secret }) => {
  const [redirect, setRedirect] = useState(false);
  const toast = useToast();
  const { handleSubmit, register, formState, control, setError } = useForm<
    yup.TypeOf<typeof RegisterAgentValidation>
  >({
    resolver: yupResolver(RegisterAgentValidation),
  });
  const { isSubmitting, errors } = formState;

  const registerAgentSubmit = async (data: RegisterAgentType) => {
    await api().get(
      `${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`
    );

    // append secret code to form data
    const payload = { ...data, secret: secret };

    await api()
      .post("/register/agent", payload)
      .then(() => {
        toast({
          title: "Success",
          description: "Registered successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setRedirect(true);
      })
      .catch((err) => {
        if (typeof err.errors === "object") {
          mapServerSideErrors(err.errors, setError);
        } else {
          toast({
            title: "Failed",
            description: "Something went wrong, try again.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          // console.log("regiteration", err);
        }
      });
  };
  return (
    <>
      {redirect && <Redirect to="/login" />}
      <Card w={"full"} maxW={"lg"} p={8} my={8}>
        <Heading size="lg" as="h4" textAlign="center" marginBottom={4}>
          Registration
        </Heading>
        <Box
          as="form"
          autoComplete="off"
          onSubmit={handleSubmit(registerAgentSubmit)}
        >
          <Stack spacing={4} direction={["column", "row"]} marginBottom={4}>
            <FormControl id="voucher" isRequired isInvalid={!!errors.voucher}>
              <FormLabel>Voucher</FormLabel>
              <InputGroup>
                <Input
                  type="voucher"
                  name="voucher"
                  placeholder="Voucher Code"
                  ref={register}
                />
              </InputGroup>
              {errors.voucher && (
                <FormErrorMessage>{errors.voucher.message}</FormErrorMessage>
              )}
              <FormHelperText>must be all lowercase</FormHelperText>
            </FormControl>
          </Stack>
          <Stack spacing={4} direction={["column", "row"]} marginBottom={4}>
            <FormControl id="name" isRequired isInvalid={!!errors.name}>
              <FormLabel>Full Name</FormLabel>
              <InputGroup>
                <Input
                  type="name"
                  name="name"
                  placeholder="Full Name"
                  ref={register}
                />
              </InputGroup>
              {errors.name && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl id="state_id" isRequired isInvalid={!!errors.state_id}>
              <FormLabel>State/Province</FormLabel>
              <InputGroup>
                <Controller
                  control={control}
                  as={Select}
                  name="state_id"
                  placeholder="Select your current state"
                  disabled={countryStatesList.length === 0}
                >
                  {countryStatesList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.state}
                    </option>
                  ))}
                </Controller>
              </InputGroup>
              {errors.state_id && (
                <FormErrorMessage>{errors.state_id.message}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>

          <Stack spacing={4} direction={["column", "row"]} marginBottom={4}>
            <FormControl id="email" isRequired isInvalid={!!errors.email}>
              <FormLabel>Email Address</FormLabel>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  placeholder="name@example.com"
                  ref={register}
                />
              </InputGroup>
              {errors.email && (
                <FormErrorMessage>{errors.email.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl id="phone" isRequired isInvalid={!!errors.phone}>
              <FormLabel>Mobile Number</FormLabel>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<PhoneIcon color="gray.300" />}
                />
                <Input
                  type="string"
                  name="phone"
                  placeholder="60123456789"
                  ref={register}
                />
              </InputGroup>
              {errors.phone && (
                <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
              )}
            </FormControl>
          </Stack>

          <Stack spacing={4} marginBottom={4} direction={["column", "row"]}>
            <FormControl id="password" isRequired isInvalid={!!errors.password}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type="password" name="password" ref={register} />
              </InputGroup>
              {errors.password && (
                <FormErrorMessage>{errors.password.message}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="password_confirmation"
              isRequired
              isInvalid={!!errors.password_confirmation}
            >
              <FormLabel>Confirm Password</FormLabel>
              <InputGroup>
                <Input
                  type="password"
                  name="password_confirmation"
                  ref={register}
                />
              </InputGroup>
              {errors.password_confirmation && (
                <FormErrorMessage>
                  {errors.password_confirmation.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>
          <Box d="flex" justifyContent="flex-end" mt={4}>
            <Button
              isLoading={isSubmitting}
              variant="solid"
              type="submit"
              loadingText="Please wait..."
              colorScheme="brand"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default RegisterAgentForm;
