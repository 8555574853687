import { Spinner } from "@chakra-ui/react";
import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import SimpleLayout from "../layouts/SimpleLayout";
import { useAuthState } from "../context/authContext";

interface LocationState {
  from: {
    pathname: string;
  };
}

const LoginPage = () => {
  const location = useLocation<LocationState>();
  const authState = useAuthState();

  const redirectTo =
    location.state && location.state.from
      ? location.state.from
      : { pathname: "/" };

  if (authState.isFetching) {
    return (
      <SimpleLayout>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.300"
          size="xl"
        />
      </SimpleLayout>
    );
  }

  return authState.authUser === null ? (
    <SimpleLayout>
      <LoginForm />
    </SimpleLayout>
  ) : (
    <Redirect to={redirectTo} />
  );
};
export default LoginPage;
