import React from "react";
import { ChakraProps, Flex } from "@chakra-ui/react";

const TopSection: React.FC<ChakraProps> = ({ children, ...rest }) => {
  return (
    <Flex
      borderBottomWidth="1px"
      p={4}
      flexDirection="column"
      roundedTopLeft={4}
      roundedTopRight={4}
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default TopSection;
