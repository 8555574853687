import * as yup from "yup";

const RegisterAgentValidation = yup
  .object()
  .shape({
    voucher: yup.string().lowercase().min(4).max(24).defined(),
    email: yup.string().email().defined(),
    name: yup.string().min(4).max(24).defined(),
    state_id: yup.number().defined(),
    phone: yup
      .string()
      .min(11, "must start with 60")
      .max(12)
      // has some bugs on update form
      // .matches(/^601[0|1|2|3|4|6|7|8|9]-*[0-9]{7,8}$/, {
      //   message: "Phone number should start with 60",
      //   // excludeEmptyString: true,
      // })
      .defined(),
    password: yup
      .string()
      .min(8, "Must be at least 8 characters")
      .matches(
        /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)[0-9a-zA-Z!@#$%^&*()]*$/,
        "Must contain at least one capital letter and number."
      )
      .defined(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .defined(),
  })
  .defined();

export default RegisterAgentValidation;
