import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  InputGroup,
  FormErrorMessage,
  Box,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "./Card";
import UpdatePasswordValidation from "../validations/UpdatePasswordValidation";
import api from "../util/api";
import mapServerSideErrors from "../util/mapServerSideErrors";
import { UpdatePasswordType } from "./types";

const UpdatePasswordForm = () => {
  const toast = useToast();
  const { handleSubmit, register, formState, setError, reset } = useForm({
    resolver: yupResolver(UpdatePasswordValidation),
  });
  const { isSubmitting, errors } = formState;

  const updatePasswordSubmit = async (data: UpdatePasswordType) => {
    await api()
      .put("/user/password", data)
      .then(() => {
        // console.log(res.data);
        reset();
        toast({
          title: "Success",
          description: "Password has been updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        // console.log(err);
        mapServerSideErrors(err.errors, setError);
      });
  };
  return (
    <Card maxW={"xl"} py={6} px={8}>
      <Stack mb={4}>
        <Heading size={"md"}>Change Password</Heading>
      </Stack>

      <Box
        as="form"
        autoComplete="off"
        onSubmit={handleSubmit(updatePasswordSubmit)}
      >
        <HStack marginBottom={4}>
          <FormControl
            id="current_password"
            isRequired
            isInvalid={!!errors.current_password}
          >
            <FormLabel>Current Password</FormLabel>
            <InputGroup>
              <Input type="password" name="current_password" ref={register} />
            </InputGroup>
            {errors.current_password && (
              <FormErrorMessage>
                {errors.current_password.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </HStack>
        <Stack spacing={4} marginBottom={4} direction={["column", "row"]}>
          <FormControl id="password" isRequired isInvalid={!!errors.password}>
            <FormLabel>New Password</FormLabel>
            <InputGroup>
              <Input type="password" name="password" ref={register} />
            </InputGroup>
            {errors.password && (
              <FormErrorMessage>{errors.password.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl
            id="password_confirmation"
            isRequired
            isInvalid={!!errors.password_confirmation}
          >
            <FormLabel>Confirm New Password</FormLabel>
            <InputGroup>
              <Input
                type="password"
                name="password_confirmation"
                ref={register}
              />
            </InputGroup>
            {errors.password_confirmation && (
              <FormErrorMessage>
                {errors.password_confirmation.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Box d="flex" justifyContent="flex-end">
          <Button
            isLoading={isSubmitting}
            variant="solid"
            type="submit"
            loadingText="Please wait..."
            colorScheme="brand"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default UpdatePasswordForm;
