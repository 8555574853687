import React from "react";
import {
  NavLink as RouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import { format, parseISO } from "date-fns";
import {
  Stack,
  Box,
  Flex,
  Icon,
  useColorModeValue,
  Button,
  Text,
  Tag,
} from "@chakra-ui/react";
import {
  // BiTachometer,
  BiExit,
  BiStats,
  BiCog,
  BiEnvelope,
} from "react-icons/bi";
import { IconType } from "react-icons/lib";
import { HiUserGroup, HiOutlineDocumentReport } from "react-icons/hi";

import api from "../util/api";
import { logout, useAuthDispatch, useAuthState } from "../context/authContext";

const SideNavLink: React.FC<{ href: string; icon: IconType }> = ({
  href,
  children,
  icon,
}) => {
  let location = useLocation();
  return (
    <Button
      as={RouterLink}
      exact
      to={href}
      justifyContent="flex-start"
      leftIcon={<Icon as={icon} w={5} h="auto" />}
      variant="ghost"
      isFullWidth
      isActive={location && location.pathname === href}
    >
      {children}
    </Button>
  );
};

const PageLinks = () => (
  <Stack spacing={1} mb={8}>
    {/* <SideNavLink href="/" icon={BiTachometer}>
      {"Dashboard"}
    </SideNavLink> */}
    <SideNavLink href="/" icon={BiStats}>
      {"Earnings"}
    </SideNavLink>
    <SideNavLink href="/agents" icon={HiUserGroup}>
      {"Agents"}
    </SideNavLink>
    <SideNavLink href="/invitations" icon={BiEnvelope}>
      {"Invitations"}
    </SideNavLink>
    <SideNavLink href="/invoices" icon={HiOutlineDocumentReport}>
      {"Invoices"}
    </SideNavLink>
    <SideNavLink href="/settings" icon={BiCog}>
      {"Settings"}
    </SideNavLink>
  </Stack>
);

const SideNav: React.FC<any> = (props) => {
  const authDispatch = useAuthDispatch();
  const { authUser } = useAuthState();
  const history = useHistory();
  const bgColor = useColorModeValue("white", "gray.800");

  const handleLogout = () => {
    api()
      .post("/logout")
      .then(() => logout(authDispatch).then(() => history.push("/login")))
      .catch((err) => console.log(err));
  };

  return (
    <Box
      backgroundColor={bgColor}
      position="fixed"
      left="0"
      width="100%"
      height="100%"
      top="0"
      right="0"
      {...props}
    >
      <Box
        top={["1rem", "4rem"]}
        position="relative"
        overflowY="auto"
        borderRightWidth="1px"
      >
        <Box>
          <Flex direction="column" fontSize="sm" p="6">
            <Stack spacing={0} align={"center"} mb={5}>
              <Text fontSize={"xl"} fontWeight={600}>
                {authUser?.name}
              </Text>
              <Tag my={1} colorScheme="green" fontWeight={"600"}>
                {authUser?.voucher}
              </Tag>

              <Text
                fontSize={"sm"}
                color={useColorModeValue("gray.400", "gray.400")}
              >
                {format(parseISO(authUser!.contract!.startDate), "dd/MMM/yy")}
                {" - "}
                {format(parseISO(authUser!.contract!.endDate), "dd/MMM/yy")}
              </Text>
            </Stack>
            <PageLinks />
            <Button
              onClick={handleLogout}
              variant="outline"
              colorScheme="red"
              leftIcon={<Icon as={BiExit} w={5} h="auto" />}
              isFullWidth
            >
              Logout
            </Button>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default SideNav;
