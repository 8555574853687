import React from "react";
import SimpleLayout from "../layouts/SimpleLayout";
import { Heading } from "@chakra-ui/react";

const NotFoundPage = () => {
  return (
    <SimpleLayout>
      <Heading>404 - Not Found!</Heading>
    </SimpleLayout>
  );
};

export default NotFoundPage;
