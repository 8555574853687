import React, { useState, useEffect } from "react";
import { Text, Spinner, Button, Stack } from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import SimpleLayout from "../layouts/SimpleLayout";
import { useAuthState } from "../context/authContext";
import RegisterAgentForm from "../components/RegisterAgentForm";
import api from "../util/api";

const RegisterAgentPage = () => {
  const { secret } = useParams<{ secret: string }>();
  const [isVerified, setIsVerified] = useState<boolean | undefined>();
  const authState = useAuthState();

  // verify secret
  useEffect(() => {
    api()
      .get(`${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`)
      .then(() => {
        api()
          .post(`/register/agent/${secret}`)
          .then((res) => setIsVerified(res.data))
          .catch((err) => console.log("err", err));
      })
      .catch((err) => console.log("csrf-cookie_err", err));
  }, [secret]);

  if (authState.authUser) {
    return (
      <SimpleLayout>
        <Stack spacing={4}>
          <Text fontSize="xl"> Logged in users can't view this page </Text>
          <Button as={Link} to="/" colorScheme="brand" variant="outline">
            Back to Dashboard
          </Button>
        </Stack>
      </SimpleLayout>
    );
  }

  if (isVerified === false) {
    return (
      <SimpleLayout>
        <Text fontSize="xl"> Link has been expired </Text>
      </SimpleLayout>
    );
  }

  return (
    <SimpleLayout>
      {isVerified === true ? (
        <RegisterAgentForm secret={secret} />
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.300"
          size="xl"
        />
      )}
    </SimpleLayout>
  );
};
export default RegisterAgentPage;
