import React from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import MobileNav from "./MobileNav";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import Logo from "./Logo";

const Header: React.FC<{}> = (props) => {
  const bgColor = useColorModeValue("white", "gray.800");

  return (
    <Box
      pos="fixed"
      as="header"
      top="0"
      zIndex="4"
      bg={bgColor}
      left="0"
      right="0"
      borderBottomWidth="1px"
      width="full"
      {...props}
    >
      <Box width="full" mx="auto" pr={[1, 6]} height="100%">
        <Flex size="100%" p={3} align="center" justify="space-between">
          <Box
            as="a"
            d="block"
            href="/"
            ml={[0, 3]}
            aria-label="daydrink, Back to homepage"
          >
            <Logo w="120px" />
          </Box>

          <Flex align="center" color="gray.500">
            <ColorModeSwitcher />
            <MobileNav />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
