import React, { useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  InputGroup,
  FormErrorMessage,
  Box,
  useToast,
  Select,
  InputLeftElement,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "./Card";
import UpdateProfileValidation from "../validations/UpdateProfileValidation";
import api from "../util/api";
import mapServerSideErrors from "../util/mapServerSideErrors";
import { PhoneIcon } from "@chakra-ui/icons";
import { UpdateProfileType } from "./types";
import { useAuthState, useAuthDispatch } from "../context/authContext";
import { countryStatesList } from "../assets/countryStatesList";

const UpdateProfileForm = () => {
  const authDispatch = useAuthDispatch();
  const { authUser } = useAuthState();
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState,
    reset,
    setError,
    control,
  } = useForm({
    resolver: yupResolver(UpdateProfileValidation),
  });
  const { isSubmitting, errors, isDirty } = formState;

  useEffect(() => {
    reset(authUser!);
  }, [reset, authUser]);

  const updateProfileSubmit = async (data: UpdateProfileType) => {
    await api()
      .put("/user/profile-information", data)
      .then(() => {
        // console.log(res.data);

        //  update auth user data
        let UpdatedCountryState = countryStatesList.find(
          (item) => item.id === data.state_id
        );

        let updatedUserData = {
          ...authUser,
          ...data,
          state: UpdatedCountryState,
        };

        // check if user has changed his email
        if (authUser?.email !== data.email) {
          updatedUserData = { ...updatedUserData, hasVerifiedEmail: false };
        }
        authDispatch({ type: "updateAuthUser", payload: updatedUserData });

        toast({
          title: "Success",
          description: "Profile information updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        if (typeof err.errors === "object") {
          mapServerSideErrors(err.errors, setError);
        }
        //  else {
        //   console.log(err);
        // }
      });
  };

  return (
    <Card maxW={"xl"} py={6} px={8}>
      <Stack mb={4}>
        <Heading size={"md"}>Update Profile</Heading>
      </Stack>

      {/* {!authUser?.hasVerifiedEmail ? (
        <Alert status="warning" my={6}>
          <AlertIcon />
          Please verify your email address to be able to reset your password in
          case you forgot it.
        </Alert>
      ) : null} */}
      <Box
        as="form"
        autoComplete="off"
        onSubmit={handleSubmit(updateProfileSubmit)}
      >
        <Stack spacing={4} direction={["column", "row"]}>
          <FormControl id="name" isRequired isInvalid={!!errors.name}>
            <FormLabel>Full Name</FormLabel>
            <InputGroup>
              <Input
                type="name"
                name="name"
                placeholder="Full Name"
                ref={register}
              />
            </InputGroup>
            {errors.name && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="email" isRequired isInvalid={!!errors.email}>
            <FormLabel>Email Address</FormLabel>
            <InputGroup>
              <Input
                type="email"
                name="email"
                placeholder="name@example.com"
                ref={register}
              />
              {/* <InputRightElement width="4.5rem">
                {authUser!.hasVerifiedEmail ? (
                  <CheckIcon color="green.500" />
                ) : (
                  <Button h="1.75rem" size="sm">
                    Verify
                  </Button>
                )}
              </InputRightElement> */}
            </InputGroup>
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>

        <Stack mt={4} spacing={4} direction={["column", "row"]}>
          <FormControl id="state_id" isRequired isInvalid={!!errors.state_id}>
            <FormLabel>State/Province</FormLabel>
            <InputGroup>
              <Controller
                control={control}
                as={Select}
                name="state_id"
                placeholder="Select your current state"
                disabled={countryStatesList.length === 0}
                defaultValue={authUser?.state!.id}
              >
                {countryStatesList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.state}
                  </option>
                ))}
              </Controller>
            </InputGroup>
            {errors.state_id && (
              <FormErrorMessage>{errors.state_id.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl id="phone" isRequired isInvalid={!!errors.phone}>
            <FormLabel>Mobile Number</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<PhoneIcon color="gray.300" />}
              />
              <Input
                type="string"
                name="phone"
                placeholder="60123456789"
                ref={register}
              />
            </InputGroup>
            {errors.phone && (
              <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Box d="flex" justifyContent="flex-end" mt={4}>
          <Button
            isLoading={isSubmitting}
            isDisabled={!isDirty}
            variant="solid"
            type="submit"
            loadingText="Please wait..."
            colorScheme="brand"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default UpdateProfileForm;
