import { Stack } from "@chakra-ui/react";
import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import CommissionTable from "./CommissionsTable";
import CommissionStatsCard from "./CommissionStatsCard";

const Earnings: React.FC<{}> = () => {
  return (
    <DashboardLayout width="full" maxWidth="1280px" mx="auto" px={6} py={6}>
      <Stack spacing={6}>
        <CommissionStatsCard />
        <CommissionTable />
      </Stack>
    </DashboardLayout>
  );
};
export default Earnings;
