export const malaysiaBanksList = [
  { id: 1, title: "AFFIN" },
  { id: 2, title: "AGRO" },
  { id: 3, title: "ALLIANCE" },
  { id: 4, title: "ALRAJHI" },
  { id: 5, title: "AMBANK" },
  { id: 6, title: "BANK ISLAM" },
  { id: 7, title: "BANK OF AMERICA" },
  { id: 8, title: "BANK OF CHINA" },
  { id: 9, title: "BANK RAKYAT" },
  { id: 10, title: "BNP PARIBAS" },
  { id: 11, title: "BSN" },
  { id: 12, title: "CIMB" },
  { id: 13, title: "CITIBANK" },
  { id: 14, title: "DEUTSCHE" },
  { id: 15, title: "HONG LEONG" },
  { id: 16, title: "HSBC" },
  { id: 17, title: "ICBC" },
  { id: 18, title: "JP MORGAN" },
  { id: 19, title: "KUWAIT FH" },
  { id: 20, title: "MAYBANK" },
  { id: 21, title: "MIZUHO" },
  { id: 22, title: "MUAMALAT" },
  { id: 23, title: "MUFG" },
  { id: 24, title: "OCBC" },
  { id: 25, title: "PUBLIC BANK" },
  { id: 26, title: "RHB" },
  { id: 27, title: "SMBC" },
  { id: 28, title: "STANDARD CHARTERED" },
  { id: 29, title: "UOB" },
];
