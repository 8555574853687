import React from "react";
import { Badge } from "@chakra-ui/react";

const GenerateStatusBadge = ({ value }: { value: string }) => {
  const color = function (value: string) {
    switch (value) {
      case "COMPLETED":
        return "green";

      case "PROCESSING":
        return "yellow";

      case "PENDING":
        return "orange";

      case "FAILED":
        return "red";

      case "CANCELLED":
        return "red";

      default:
        return "gray";
    }
  };

  return (
    <Badge size="sm" key={value} variant="subtle" colorScheme={color(value)}>
      {value}
    </Badge>
  );
};

export default GenerateStatusBadge;
