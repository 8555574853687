import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { format, formatDistance, parseISO } from "date-fns";
import { BiShareAlt } from "react-icons/bi";

const columns = [
  {
    Header: "Link",
    accessor: "link",
    Cell: ({ value }: { value: string }) => {
      return (
        <HStack alignItems="center">
          <Text as="u" display="inline">
            {value}
          </Text>
          <BiShareAlt />
        </HStack>
      );
    },
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: ({ value }: { value: string }) => {
      return formatDistance(parseISO(value), new Date(), { addSuffix: true });
    },
  },
  {
    Header: "Expires At",
    accessor: "expiresAt",
    Cell: ({ value }: { value: string }) => {
      return format(parseISO(value), "dd/MMM/yy");
    },
  },
];

export default columns;
