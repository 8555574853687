export const countryStatesList = [
  {
    id: 1,
    country: "Malaysia",
    countryCode: "MY",
    state: "Johor",
    stateCode: "JHR",
  },
  {
    id: 2,
    country: "Malaysia",
    countryCode: "MY",
    state: "Kedah",
    stateCode: "KDH",
  },
  {
    id: 3,
    country: "Malaysia",
    countryCode: "MY",
    state: "Kelantan",
    stateCode: "KTN",
  },
  {
    id: 4,
    country: "Malaysia",
    countryCode: "MY",
    state: "Melaka",
    stateCode: "MLK",
  },
  {
    id: 5,
    country: "Malaysia",
    countryCode: "MY",
    state: "Negeri Sembilan",
    stateCode: "NSN",
  },
  {
    id: 6,
    country: "Malaysia",
    countryCode: "MY",
    state: "Pahang",
    stateCode: "PHG",
  },
  {
    id: 7,
    country: "Malaysia",
    countryCode: "MY",
    state: "Perak",
    stateCode: "PRK",
  },
  {
    id: 8,
    country: "Malaysia",
    countryCode: "MY",
    state: "Perlis",
    stateCode: "PLS",
  },
  {
    id: 9,
    country: "Malaysia",
    countryCode: "MY",
    state: "Pulau Pinang",
    stateCode: "PNG",
  },
  {
    id: 10,
    country: "Malaysia",
    countryCode: "MY",
    state: "Sabah",
    stateCode: "SBH",
  },
  {
    id: 11,
    country: "Malaysia",
    countryCode: "MY",
    state: "Sarawak",
    stateCode: "SWK",
  },
  {
    id: 12,
    country: "Malaysia",
    countryCode: "MY",
    state: "Selangor",
    stateCode: "SGR",
  },
  {
    id: 13,
    country: "Malaysia",
    countryCode: "MY",
    state: "Terengganu",
    stateCode: "TRG",
  },
  {
    id: 14,
    country: "Malaysia",
    countryCode: "MY",
    state: "W.P. Kuala Lumpur",
    stateCode: "KUL",
  },
  {
    id: 15,
    country: "Malaysia",
    countryCode: "MY",
    state: "W.P. Labuan",
    stateCode: "LBN",
  },
  {
    id: 16,
    country: "Malaysia",
    countryCode: "MY",
    state: "W.P. Putrajaya",
    stateCode: "PJY",
  },
];
