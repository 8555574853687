import React from "react";
import { Stack } from "@chakra-ui/react";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import UpdateProfileForm from "../components/UpdateProfileForm";
import DashboardLayout from "../layouts/DashboardLayout";
import UpdateBankAccountForm from "../components/UpdateBankAccountForm";

const Settings = () => {
  return (
    <DashboardLayout width="full" maxWidth="1280px" mx="auto" px={6} py={6}>
      <Stack spacing={6}>
        <UpdateBankAccountForm />
        <UpdateProfileForm />
        <UpdatePasswordForm />
      </Stack>
    </DashboardLayout>
  );
};
export default Settings;
