import React from "react";
import { Filters, SortingRule } from "react-table";
import { Text, useDisclosure } from "@chakra-ui/react";
import Table from "../../../components/Table";
import columns from "./COLUMNS";
import api from "../../../util/api";
import ShareLinkModal from "../../../components/ShareLinkModal";

const InvitationsTable: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [rowLink, setRowLink] = React.useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({
      pageIndex,
    }: {
      pageIndex: number;
      filters: Filters<{}>;
      sortBy: SortingRule<{}>[];
    }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        api()
          .get(`/invitation?page=${pageIndex + 1}`)
          .then((res) => {
            setData(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("invitations table", err);
            setLoading(false);
          });
      }
    },
    []
  );

  function openShareModal(link: string) {
    setRowLink(link);
    onOpen();
  }

  return (
    <>
      <ShareLinkModal onClose={onClose} isOpen={isOpen} link={rowLink} />
      <Table
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={loading}
        pageCount={-1}
        onRowClick={(row) => openShareModal(row.values.link as string)}
      />
      <Text color="gray.500" style={{ marginTop: "0.5rem" }}>
        &#9913; Click on each link to share
      </Text>
    </>
  );
};
export default InvitationsTable;
