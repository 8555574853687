import React from "react";
import { Spinner, Stack, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import Card from "../../components/Card";

type InvitationStatsResponse = {
  completedOrders: number;
  currentAgents: number;
  invitationsCount: number;
  quota: number;
} | null;

const InvitationStatsCard = ({ stats }: { stats: InvitationStatsResponse }) => {
  return (
    <Card py={6} px={8}>
      {stats ? (
        <>
          <Stack direction={["column", "row"]} spacing={2}>
            <Stat textAlign="center">
              <StatLabel>Available Quota</StatLabel>
              <StatNumber>{stats?.quota}</StatNumber>
            </Stat>

            <Stat textAlign="center">
              <StatLabel>Completed Orders</StatLabel>
              <StatNumber>{stats?.completedOrders}</StatNumber>
            </Stat>

            <Stat textAlign="center">
              <StatLabel>Current Agents</StatLabel>
              <StatNumber>{stats?.currentAgents}</StatNumber>
            </Stat>

            <Stat textAlign="center">
              <StatLabel>Active Invitations</StatLabel>
              <StatNumber>{stats?.invitationsCount}</StatNumber>
            </Stat>
          </Stack>
        </>
      ) : (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.300"
          size="lg"
        />
      )}
    </Card>
  );
};

export default InvitationStatsCard;
