import React from "react";
import { Stack } from "@chakra-ui/react";
import DashboardLayout from "../../layouts/DashboardLayout";
import AgentsTable from "./AgentsTable";

const Agents = () => {
  return (
    <DashboardLayout width="full" maxWidth="1280px" mx="auto" px={6} py={6}>
      <Stack spacing={6}>
        <AgentsTable />
      </Stack>
    </DashboardLayout>
  );
};
export default Agents;
