import {
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import React from "react";
import { BiFilterAlt } from "react-icons/bi";
import { UseFiltersColumnProps } from "react-table";

const StatusFilterList = ({
  column,
}: {
  column: UseFiltersColumnProps<{}>;
}) => {
  const { filterValue, setFilter } = column;

  const options = [
    "COMPLETED",
    "PENDING",
    "PROCESSING",
    "CANCELLED",
    "FAILED",
    "REFUNDED",
    "ON-HOLD",
  ];

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<BiFilterAlt />}
        size="sm"
        variant="ghost"
      />
      <MenuList>
        <MenuOptionGroup
          defaultValue={filterValue}
          type="radio"
          onChange={(value) => {
            setFilter(value || undefined);
          }}
        >
          <MenuItemOption key="all" value={""}>
            All
          </MenuItemOption>
          {options.map((option) => (
            <MenuItemOption key={option} value={option}>
              {option}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default StatusFilterList;
