import React, { useEffect } from "react";
import { Switch, Route, Redirect, RouteProps } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "./context/authContext";
import Agents from "./pages/Agents";
// import Home from "./pages/Home";
import Earnings from "./pages/Earnings";
import Login from "./pages/Login";
import Settings from "./pages/Settings";
import Invoices from "./pages/Invoices";
import Invitations from "./pages/Invitations";
import RegisterAgent from "./pages/RegisterAgent";
import NotFound from "./pages/NotFound";
import api from "./util/api";

const Routes = () => {
  const authDispatch = useAuthDispatch();

  // check if user is already logged in
  useEffect(() => {
    const isAuth = localStorage.getItem(
      `${process.env.REACT_APP_LOCAL_STORAGE_PREFIX}-isAuth`
    );
    if (isAuth === "true") {
      (async function fetchAuthUser() {
        // get logged user
        await api().get(
          `${process.env.REACT_APP_API_BASE_URL}/sanctum/csrf-cookie`
        );
        await api()
          .get("/user/me")
          .then((res) => {
            // console.log("/user/me", res.data);
            authDispatch({ type: "updateAuthUser", payload: res.data.data });
          });
      })();
    } else {
      authDispatch({ type: "setAuthIsFetching", payload: false });
    }
  }, [authDispatch]);

  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register/agent/:secret">
        <RegisterAgent />
      </Route>
      {/* <PrivateRoute exact path="/">
        <Home />
      </PrivateRoute> */}
      {/* dashboard temporary disabled and set to earnings */}
      <PrivateRoute exact path="/">
        <Earnings />
      </PrivateRoute>
      <PrivateRoute exact path="/agents">
        <Agents />
      </PrivateRoute>
      <PrivateRoute exact path="/invoices">
        <Invoices />
      </PrivateRoute>
      <PrivateRoute exact path="/invitations">
        <Invitations />
      </PrivateRoute>
      <PrivateRoute exact path="/settings">
        <Settings />
      </PrivateRoute>
      <Route exact path="/not-found" component={NotFound} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  // let auth = useAuth();
  const authState = useAuthState();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.authUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
