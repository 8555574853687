import React from "react";
import { Filters, SortingRule } from "react-table";
import Table from "../../../components/Table";
import columns from "./COLUMNS";
import api from "../../../util/api";

const AgentsTable: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({
      pageIndex,
    }: {
      pageIndex: number;
      filters: Filters<{}>;
      sortBy: SortingRule<{}>[];
    }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        api()
          .get(`/agent?page=${pageIndex + 1}`)
          .then((res) => {
            setData(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("agents table", err);
            setLoading(false);
          });
      }
    },
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      fetchData={fetchData}
      isLoading={loading}
      pageCount={-1}
    />
  );
};
export default AgentsTable;
