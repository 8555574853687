import React from "react";
import { Filters, SortingRule } from "react-table";
import Table from "../../../components/Table";
import columns from "./COLUMNS";
import api from "../../../util/api";

const CommissionsTable: React.FC<{}> = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({
      pageIndex,
      filters,
      sortBy,
    }: {
      pageIndex: number;
      filters: Filters<{}>;
      sortBy: SortingRule<{}>[];
    }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // Only update the data if this is the latest fetch
      if (fetchId === fetchIdRef.current) {
        const status =
          typeof filters.find((x) => x.id === "status") === "object"
            ? filters.find((x) => x.id === "status")?.value
            : "";
        const sort = sortBy[0];

        api()
          .get(
            `/commission?page=${
              pageIndex + 1
            }&status=${status}&sort_field=created_at&sort_direction=${
              sort && sort.desc === true ? "desc" : "asc"
            }`
          )
          .then((res) => {
            setData(res.data.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log("CommissionsTable ", err);
            setLoading(false);
          });
      }
    },
    []
  );

  return (
    <Table
      columns={columns}
      data={data}
      fetchData={fetchData}
      isLoading={loading}
      pageCount={-1}
    />
  );
};
export default CommissionsTable;
